import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import { Select } from 'components/Select';
import { useAsync } from 'react-async';
import { search } from '../api/search';
import PageContext from '../contexts/page';

const KoediaLocationField = ({
  variant,
  excludes,
  shouldReturnLocation,
  ...props
}) => {
  const [field] = useField(props.name);
  const formik = useFormikContext();
  const { language } = useContext(PageContext);
  const { placeholder } = props;
  const { data: results = [], run } = useAsync({
    deferFn: ([query]) => search({ query, language: language, excludes }),
  });

  const handleSearch = query => {
    run(query);
  };

  const handleChange = async item => {
    formik.setFieldValue('kind', item.kind);
    formik.setFieldValue('id', item.id);
    if (shouldReturnLocation) {
      formik.setFieldValue(`location`, item.label);
    }
  };

  const initialInputValue = field.value || [];

  return (
    <Select
      hasInput
      hasIcon
      options={results.map(result => ({
        ...result,
        label: result.title,
        value: `${result.kind}-${result.id}`,
      }))}
      onInputValueChange={handleSearch}
      onChange={handleChange}
      initialInputValue={initialInputValue}
      variant={variant}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default KoediaLocationField;
