import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import AuthContext from 'contexts/auth';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { useSearchParam } from 'react-use';
import { useQueryParams } from 'hooks';
import SEO from 'components/seo';
import { useGTM, useDataLayerBuilder } from '@tymate/react-gtm';
import Layout from '../components/Layout';
import { Container } from 'ui/containers';
import HotelCards from 'components/HotelCards';
import { Button } from 'ui';
import BannerNotreSelectionHotel from '../assets/images/BannerNotreSelectionHotel.jpg';
import PageContext from 'contexts/page';
import { getHotels } from 'api/hotels';
import Spinner from 'components/Spinner';
import Filters from '../components/Filters';
import FiltersModal from 'components/FiltersModal';
import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { media, SpinnerContainer } from 'ui';
import HotelMarker from 'components/Markers/HotelMarker';
import ToggleSwitch from 'components/ToggleSwitch';

import { MapContainer, SearchMap } from 'components/MyMap';
import InfiniteScroll from 'react-infinite-scroll-component';
import HeroHeader from '../components/HeroHeader';

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.medium`
    flex-direction: row;
  `}
`;
const LeftContainer = styled.div`
  overflow: scroll;
  height: calc(100vh - 164px);
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(0)};

  > * {
    padding-top: 0;
    + * {
      padding-top: ${({ theme }) => theme.spacing(3)};
    }
  }

  ${media.desktop`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const RightContainer = styled.div`
  display: none;
  flex-shrink: 0;
  width: 40vw;
  height: calc(100vh - 165px);

  ${media.desktop`
    display: block;
    margin-top: ${({ theme }) => theme.spacing(0)};
  `};
`;

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  color: #fff;
`;
const ToggleModule = styled.div`
  position: absolute;
  right: 18%;
  margin-right: ${({ theme }) => theme.spacing(2)};
  display: none;
  align-items: center;

  ${media.desktop`
    display: flex;
  `};
`;
const MapLabel = styled.div`
  color: ${({ theme }) => theme.text};
  margin-right: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(3)};
`;

export const query = graphql`
  query($language: String!, $limit: Int, $skip: Int) {
    allHotel(
      filter: { language: { eq: $language } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        originalId
        displayName
        stars
        styles {
          displayName
        }
        sustainable {
          level
        }
        officialCategories {
          displayName
        }
        city {
          slug
          title
          country {
            title
            slug
          }
        }
        primaryAttachment {
          smallUrl
          mediumUrl
        }
        secondaryAttachments {
          mediumUrl
        }
      }
    }
  }
`;

const Hotels = () => {
  const { t } = useTranslation();
  const { pushDataLayer } = useGTM();
  const { isAuthenticated } = useContext(AuthContext);
  const { language } = useContext(PageContext);
  useDataLayerBuilder(() => ({
    pageType: 'hotels',
  }));
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const queryParams = useQueryParams();

  const countryId = useSearchParam('countryId');
  const regionId = useSearchParam('regionId');
  const cityId = useSearchParam('cityId');
  const { type, style, category, stars, environment, sustainability } = queryParams;
  const [visibilityMap, setVisibilityMap] = useState(true);

  const mapRef = useRef();
  const handleVisibilityMap = () => {
    setVisibilityMap(!visibilityMap);
  };
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(true);
  const [hotels, setHotels] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = useMemo(
    () => ({
      options: {
        filter: {
          countryId,
          cityId,
          regionId,
          type: Boolean(type) ? type : null,
          style: Boolean(style) ? style : null,
          category: Boolean(category) ? category : null,
          stars: Boolean(stars) ? stars : null,
          sustainability: Boolean(sustainability) ? sustainability : null,
          environment: Boolean(environment) ? environment : null,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryId, cityId, type, category, style, stars, sustainability, environment],
  );

  const { data,cancel } = useAsync({
    promiseFn: getHotels,
    size: 40,
    options: params.options,
    number: page,
    language,
    watch: load,
    onResolve: useCallback(
      ({ body: hotels }) => {
        pushDataLayer({
          ecommerce: {
            impressions: hotels.map((hotel, index) => ({
              id: hotel?.id,
              name: hotel?.displayName,
              brand: 'Feelingo',
              category: 'Hotel',
              position:
                index + 1 + (page - 1) * 440,
            })),
          },
        });
      },
      // eslint-disable-next-line
      [pushDataLayer],
    ),
  });

  const pagination = data?.pagination

  useEffect(() => {
    if (data) {
      cancel();
      if (page===1) {
        setHotels(() => data.body);
      } else {
        setHotels(() => hotels.concat(data.body));
      }
      setHasMore(pagination.totalPages > pagination.currentPage)
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    cancel();
    setIsLoading(true);
    setHotels([]);
    setPage(1);
    setLoad(!load);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const PageUp = () => {
    setPage(page+1);
    setLoad(!load);
  };

  return (
    <Layout>
      <SEO title={t('pageTitle.hotels')} />
      <HeroHeader
        image={BannerNotreSelectionHotel}
        title={t('header.hotels')}
      />
      <Container hasBottomBorder>
        <Filters excludes={['Hotel']} />
        <Stack alignX="flex-end" size="full">
          <ToggleModule isLeftAlign>
            <Button
              variant="secondOutline"
              isRightAlign
              onClick={() => setIsOpen(true)}
            >
              {t('hotel.filters.title')}
            </Button>
            <MapLabel>
              {visibilityMap ? t('searchBar.hide') : t('searchBar.show')}
            </MapLabel>
            <ToggleSwitch
              checked={visibilityMap}
              onChange={handleVisibilityMap}
            />
          </ToggleModule>
        </Stack>

        <FiltersModal isOpen={isOpen} onClose={onClose} />
      </Container>
      <SplitContainer>
          <LeftContainer id="scrollableDiv">
            {isLoading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
            <InfiniteScroll
              dataLength={hotels.length}
              next={PageUp}
              scrollableTarget="scrollableDiv"
              hasMore={hasMore}
              loader={
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              }
            >
              <HotelCards
                hotels={hotels}
                isAuthenticated={isAuthenticated}
                visibilityMap={visibilityMap}
              />
            </InfiniteScroll>
            )}
          </LeftContainer>
        {visibilityMap && (
          <RightContainer>
            <MapWrapper>
              <MapContainer>
                <SearchMap
                  ref={mapRef}
                  hotels={hotels}
                  markers={hotels
                    .filter(hotel => Boolean(hotel.lat) && Boolean(hotel.lon))
                    .map(hotel => ({
                      ...hotel,
                      lat: Number(hotel.lat),
                      lng: Number(hotel.lon),
                    }))
                    .map(hotel => (
                      <HotelMarker
                        hotel={hotel}
                        id={hotel.id}
                        lat={hotel.lat}
                        lng={hotel.lng}
                      />
                    ))}
                />
              </MapContainer>
            </MapWrapper>
          </RightContainer>
        )}
      </SplitContainer>
    </Layout>
  );
};

export default Hotels;
