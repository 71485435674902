import React, { useContext } from 'react';
import styled from 'styled-components';
import { formatSearch } from '../utils';
import { navigate } from '@reach/router';
import { Formik, Form } from 'formik';
import KoediaLocationField from './KoediaLocationField';
import { Select } from 'components/Select';
import { useLocation } from 'react-use';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import { Button as RawButton, media } from '../ui';
import { MdSearch } from 'react-icons/md';
import { SearchWrapper as RawSearchWrapper } from 'components/SearchBar';
import { ButtonWrapper } from './SearchBar';
import { getThematics } from '../api/articles';
import PageContext from '../contexts/page';
import { useQueryParams } from 'hooks';

const SearchWrapper = styled(RawSearchWrapper)`
  max-width: 100%;
  display: flex;
  flex-direction: column;

  p {
    display: block;
  }

  svg {
    display: none;
  }

  ${media.tablet`
    flex-direction: row;
    align-items: center;
    & > * + * {
      margin-top: 0;
      margin-left: ${({ theme }) => theme.spacing()};
    }
    svg {
      display: block;
    }
    p {
      display: none;
    }
  `}

  ${media.desktop`
    & > * + * {
      margin-left: 0;
    }
  `}
`;

const Button = styled(RawButton)`
  ${media.tablet`
    min-width: 32px;
    max-width: 64px;
  `}

  ${media.desktop`
    min-width: 80px;
    max-width: 96px;
  `}
`;

const Filters = ({ withThematics, excludes }) => {
  const { language } = useContext(PageContext);
  const { data = [] } = useAsync({ promiseFn: getThematics, language });
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();

  const { thematic, location } = queryParams;

  const handleFilters = values => {
    const { kind, id, ...filters } = values;
    const search = formatSearch({
      ...(kind === 'City' ? { cityId: id } : {}),
      ...(kind === 'Country' ? { countryId: id } : {}),
      ...(kind === 'Region' ? { regionId: id } : {}),
      ...(kind === 'Hotel' ? { hotelId: id } : {}),
      ...filters,
    });

    navigate(`${pathname}?${search}`);
  };

  const handleChange = (input, inputType, setFieldValue) =>
    setFieldValue(inputType, [input.value]);

  const getInitialValues = () => {
    let initialValues = {};
    if (queryParams?.countryId) {
      initialValues = {
        ...initialValues,
        kind: 'Country',
        id: queryParams.countryId,
      };
    }
    if (queryParams?.cityId) {
      initialValues = {
        ...initialValues,
        kind: 'City',
        id: queryParams.cityId,
      };
    }
    if (queryParams?.regionId) {
      initialValues = {
        ...initialValues,
        kind: 'Region',
        id: queryParams.regionId,
      };
    }
    if (Boolean(location)) {
      initialValues = {
        ...initialValues,
        location,
      };
    }
    if (Boolean(withThematics) && Boolean(thematic)) {
      initialValues = {
        ...initialValues,
        thematic: Boolean(thematic) ? Number(thematic[0]) : null,
      };
    }

    return initialValues;
  };

  return (
    <Formik initialValues={getInitialValues()}>
      {({ values, setFieldValue }) => (
        <Form
          onSubmit={e => {
            e.preventDefault();
            handleFilters(values);
          }}
        >
          <SearchWrapper variant="home">
            <KoediaLocationField
              name="location"
              variant={'home'}
              full
              placeholder={t('searchBar.placeFilter')}
              type="location"
              value={values.location}
              excludes={excludes}
              shouldReturnLocation
              isSearchIcon={false}
            />
            {withThematics && (
              <Select
                name="thematic"
                onChange={value =>
                  handleChange(value, 'thematic', setFieldValue)
                }
                options={[
                  { label: t('articles.thematic.unselected'), value: '' },
                  ...(data.thematics || []).map(result => ({
                    label: result.displayName,
                    value: result.id,
                  })),
                ]}
                value={values.thematic}
                placeholder={t('utils.thematic')}
                variant="borderless"
              />
            )}
            <Button square variant="home">
              <ButtonWrapper square>
                <MdSearch style={{ fontSize: 28 }} />
                <p>{t('utils.research')}</p>
              </ButtonWrapper>
            </Button>
          </SearchWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default Filters;
